import React, { useEffect, useState } from "react"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { graphql, PageProps } from "gatsby"
import getSearchParam from "@ecom/ui/utils/getSearchParam"
import Layout from "../components/Layouts/mainPage"
import { IndexPageData } from "../interfaces/pageProps"

import { BannerText, getDynamicBannerText } from "../helpers/getDynamicBannerText"
import { getDynamicBannerUrl } from "../helpers/getDynamicBannerUrl"
import { getIndexPageData } from "../helpers/getIndexPageData"

import { NewHeader } from "../components/Headers/NewHeader"
import { Banner as BannerOrder } from "../components/Banners/OrderIndex"
import { Banner as RoundedDynamicBanner } from "../components/Banners/DynamicBanners/RoundedDynamicBanner"
import { HowInstallmentWorks } from "../components/HowInstallmentWorks"
import { HowToGetCard } from "../components/HowToGetCard"
import { FormPKW } from "../components/FormPKW"
import { NewCalculator } from "../components/NewCalculator/NewCalculator"
import { MoreBenefitsRedisign } from "../components/MorBenefitsRedisgn"
import { FourAdvantagesPoints } from "../components/FourAdvantagesPoints"
import { OtherCards } from "../components/OtherCards"
import { BottomCta, RefinSeoBgImg } from "../components/BottomCta"
import { Partners } from "../components/PartnersNew"
import { Reviews } from "../components/Reviews"
import { Questions } from "../components/Questions"
import { NewFooter } from "../components/NewFooter"
import { QUESTIONS_FOR_GETHALVA, QUESTIONS_FOR_HALVA_PKW } from "../components/Questions/helpers"

import { Banner as DynamicBanner } from "../components/Banners/DynamicBanners/DefaultDynamicBanner"
import { STEPS_MEDIA_D } from "../components/HowToGetCard/helpers"

const titleForm = (
  <>
    Оформите карту сейчас – <br /> получите подарок!
  </>
)

const countDownTime = 1800000

const defaultTitleMob = `
Рассрочка до 24 мес. </br>
по карте «Халва»
`

const defaultTitle = `
Рассрочка до 24 месяцев </br>
по карте «Халва»
`

const bannerDescription = `
— До 3% к ставке вклада
<br />
— Кэшбэк до 10%
<br />
— Обслуживание 0 ₽ 
`

const subTitlePartners = (
  <>
    Совершайте покупки в&nbsp;магазинах-партнерах и&nbsp;получайте до&nbsp;24&nbsp;месяцев рассрочки
  </>
)

const GIFT_TITLE = (
  <>
    Оформите Халву и получите
    <br />
    <b> месяц&nbsp;подписки&nbsp;в&nbsp;подарок</b>
  </>
)

const subTitlePartnersGetHalva = `Любые покупки у партнеров в рассрочку`

export default function Page({ data }: PageProps<IndexPageData>) {
  const { ligalOrder, noIndex, ligalGethalva, isHiddenPageGetHalva } = getIndexPageData(data)
  const { halvaOstatok } = data.admin
  const [dynamicBannerTextObj, setDynamicBannerTextObj] = useState<BannerText>()
  const [dynamicBannerImagesObj, setDynamicBannerImagesObj] = useState<any>()

  const bannerTextUrl = getSearchParam("h")
  const bannerImageUrl = getSearchParam("bn")

  const isMob = useMediaQuery("(max-width:599.5px)")
  const finalBannertitle = isMob ? defaultTitleMob : defaultTitle

  useEffect(() => {
    if (bannerImageUrl) {
      const bannerImagesData = getDynamicBannerUrl(data.allDynamicBanner, bannerImageUrl)
      if (bannerImagesData !== null) {
        setDynamicBannerImagesObj(bannerImagesData)
      }
    }
  }, [bannerImageUrl, data.allDynamicBanner])

  useEffect(() => {
    if (bannerTextUrl) {
      getDynamicBannerText(bannerTextUrl).then((result) => {
        setDynamicBannerTextObj(result)
      })
    }
  }, [bannerTextUrl])

  if (!halvaOstatok) {
    throw new Error("The halvaOstatok variable is required!!!")
  }

  // Получение первой страницы на которую зашел
  useEffect(() => {
    if (!sessionStorage.getItem("firstURL")) {
      sessionStorage.setItem("firstURL", document.URL)
    }
  }, [])

  if (process.env.GATSBY_SITE_URL === "https://gethalva.ru") {
    return (
      <Layout noHeader noFooter noIndex={isHiddenPageGetHalva}>
        <NewHeader logoClick={false} redLogoWithSovcombank />
        {dynamicBannerImagesObj && bannerImageUrl ? (
          <DynamicBanner
            large={dynamicBannerImagesObj.dymanicBannerDesk}
            mob={dynamicBannerImagesObj.dymanicBannerMob}
            title={dynamicBannerTextObj ? dynamicBannerTextObj.title : finalBannertitle}
            description={
              dynamicBannerTextObj ? dynamicBannerTextObj.description : bannerDescription
            }
            orderNum="1"
          />
        ) : (
          <BannerOrder orderNum="1" />
        )}
        <FourAdvantagesPoints
          withTitle
          indent
          variant="newYearMedia"
          title="Преимущества с Халвой:"
          orderNum="2"
        />
        <FormPKW
          withTimer
          dataLayerName="shortPersonalForm"
          hasBirth
          longTitle
          orderNum="3"
          title={titleForm}
          countDownTime={countDownTime}
        />
        <HowToGetCard items={STEPS_MEDIA_D} orderNum="4" />
        <NewCalculator darkColor orderNum="5" />
        <OtherCards orderNum="6" />
        <Partners
          title="Более 260&nbsp;000 магазинов-партнеров"
          subTitle={subTitlePartnersGetHalva}
          orderNum="7"
        />
        <MoreBenefitsRedisign
          additionalEventInDataLayer
          orderNum="8"
          hasSubtitle
          bottomBlock={false}
        />
        <BottomCta variant="pkwRedesign" title={GIFT_TITLE} BgImg={RefinSeoBgImg} orderNum="9" />
        <Reviews additionalEventInDataLayer orderNum="10" />
        <Questions questionList={QUESTIONS_FOR_GETHALVA} orderNum="11" />
        <NewFooter ligal={ligalGethalva} orderNum="12" />
      </Layout>
    )
  }

  return (
    <Layout
      noHeader
      noFooter
      noIndex={noIndex}
      seoData={{
        title: "До 24 месяцев рассрочки с Халвой на покупки у партнеров",
        description:
          "Оформите карту Халва с рассрочкой до 24 месяцев! Совершайте покупки у магазинов-партнеров и выплачивайте сумму в рассрочку до 24 месяцев. Проценты заплатит магазин.",
      }}
    >
      <NewHeader redLogoWithSovcombank countDownTime={countDownTime} />
      {dynamicBannerImagesObj && bannerImageUrl ? (
        <RoundedDynamicBanner
          large={dynamicBannerImagesObj.dymanicBannerDesk}
          mob={dynamicBannerImagesObj.dymanicBannerMob}
          title={dynamicBannerTextObj ? dynamicBannerTextObj.title : finalBannertitle}
          description={dynamicBannerTextObj ? dynamicBannerTextObj.description : bannerDescription}
          orderNum="1"
        />
      ) : (
        <BannerOrder description={bannerDescription} orderNum="1" />
      )}
      <FourAdvantagesPoints
        withTitle
        indent
        variant="pkwRedesign"
        title="Преимущества с Халвой:"
        orderNum="2"
      />
      <FormPKW
        dataLayerName="longPersonalForm"
        title={titleForm}
        longTitle
        additionalEventInDataLayer
        longForm
        countDownTime={countDownTime}
        withTimer
        formBtnText="Оформить карту"
        orderNum="3"
        onlyPKWPage
        isGreenApprove
      />
      <HowInstallmentWorks
        headTitle="Как получить рассрочку на&nbsp;покупки"
        orderNum="4"
        variant="procentyVernetMagazin"
      />
      <NewCalculator additionalEventInDataLayer orderNum="5" />
      <OtherCards orderNum="6" />
      <Partners
        title="Более 260&nbsp;000 магазинов-партнеров"
        subTitle={subTitlePartners}
        orderNum="7"
      />
      <MoreBenefitsRedisign
        additionalEventInDataLayer
        orderNum="8"
        hasSubtitle
        bottomBlock={false}
      />
      <BottomCta variant="pkwRedesign" title={GIFT_TITLE} BgImg={RefinSeoBgImg} orderNum="9" />
      <Reviews additionalEventInDataLayer orderNum="10" />
      <Questions questionList={QUESTIONS_FOR_HALVA_PKW} orderNum="11" />
      <NewFooter ligal={ligalOrder} orderNum="12" />
    </Layout>
  )
}

export const query = graphql`
  query {
    allDynamicBanner {
      edges {
        node {
          name
          deskGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [1920, 3840]
              )
            }
          }
          mobGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [600, 1200]
              )
            }
          }
        }
      }
    }
    admin {
      order: page(url: "https://halvacard.ru/order/") {
        phones
        ligal {
          text
        }
        notIndex
      }
      getHalva: page(url: "https://gethalva.ru/") {
        ligal {
          text
        }
        notIndex
      }
      halvaOstatok: variable(name: "halvaOstatok") {
        value
      }
    }
  }
`
